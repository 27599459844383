import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseAPIService } from '@shared/providers/base-api.service';
import { Official, OfficialAddEdit } from '@shared/interfaces/officials/official.interface';
import { OFFICIALS } from '@shared/constants/api-endpoints';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OfficialsService extends BaseAPIService {

  constructor(
      protected httpClient: HttpClient
  ) {
    super(httpClient);
  }

  signUpAsOfficial(credentials: OfficialAddEdit) {
      return this.post(OFFICIALS.REGISTER, credentials);
  }

  getOfficial<T>(id: Official['id']): Observable<T> {
    return this.getById(OFFICIALS.OFFICIAL, id);
  }

  getOfficialCars<T>(id: Official['id']): Observable<T> {
    return this.getList(OFFICIALS.OFFICIAL_CARS, { limit: -1, official: id });
  }

  updateOfficial(official: OfficialAddEdit, id: Official['id']) {
    return this.put(OFFICIALS.UPDATE_OFFICIAL.replace('{id}', id), official);
  }

  updateImageOfficial(file: File, id: Official['id']) {
    const formData = new FormData();
    formData.append('image', file);
    return this.put(OFFICIALS.UPDATE_IMAGE_OFFICIAL.replace('{id}', id), formData);
  }
}
