import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import { Observable } from 'rxjs';
import {AuthService} from '@shared/providers/auth/auth.service';
import {FULL_ROUTES} from '../../routes';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  private readonly fullRoutes = FULL_ROUTES;

  constructor(
    private readonly authService: AuthService,
    private readonly router: Router
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    if (this.authService.isLoggedIn) {
      return true;
    } else {
      this.router.navigate([`/${this.fullRoutes.SIGN_IN}`]);
      return false;
    }
  }

}
